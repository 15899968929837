import { useEffect, useState } from 'react'
import { CustomTable } from '../../component/CustomTable'
import api from '../../context/auth/AuthState'
import { useSearchParams } from 'react-router-dom'
import cloneSearchParams from '../../helper/cloneSearchParams'
import { Container } from '../../component/Container'
import { QazSearch } from '../../component/QazSearch'
import { Button, Card, Col, Row, Spin, Tooltip } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined, PlayCircleFilled } from '@ant-design/icons'

export const AdminWordList = () => {

    const [words, setWords] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [query, setQuery] = useState(null)
    const [loadingKeys, setLoadingKeys] = useState([])
    const [expandDataSource, setExpandDataSource] = useState({})

    const DEFAULT_PAGE = 1
    const DEFAULT_PAGE_SIZE = 10

    const [pagination, setPagination] = useState({
        current: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE
    })

    const columns = [{
        key: 'key',
        title: '#',
        dataIndex: 'key'
    }, {
        key: 'kk',
        title: 'На казахском',
        dataIndex: 'kk'
    }, {
        key: 'ru',
        title: 'На русском',
        dataIndex: 'ru'
    }]

    const getWords = (query, page, pageSize) => {
        let rowNumber = (page - 1) * pageSize;
        api.get(`/admin/word/search?query=${query}&page=${page - 1}&pageSize=${pageSize}`)
            .then(response => {
                response.data.map(x => x.key = ++rowNumber)
                const total = response.headers.get('x-total-count')
                setWords(response.data)
                setPagination(prev => ({...prev, current: page, total}))
            })
    }

    useEffect(() => {
        const query = searchParams.get('query') || ''
        const page = parseInt(searchParams.get('page')) || DEFAULT_PAGE
        const pageSize = parseInt(searchParams.get('pageSize') || DEFAULT_PAGE_SIZE)
        setPagination(prev => ({...prev, current: page, pageSize}))
        getWords(query, page, pageSize)
    }, [searchParams])

    const onSearch = value => {
        setQuery(value)
        const newSearchParams = cloneSearchParams(searchParams)
        newSearchParams.query = value
        newSearchParams.page = DEFAULT_PAGE
        setSearchParams(newSearchParams)
    }

    const onTableChange = pagination => {
        setPagination(pagination)
        const newSearchParams = cloneSearchParams(searchParams)
        newSearchParams.page = pagination.current
        newSearchParams.pageSize = pagination.pageSize
        setSearchParams(newSearchParams)
    }

    const recordNumberFragment = pagination.total > 0 ? (
        <div style={{textAlign: 'center'}}>
            Найдено записей: {pagination.total}
        </div>
    ) : null

    const onRowExpand = (expanded, record) => {
        console.log('onRowExpand', expanded, record)
        if(expanded) {
            setLoadingKeys(prev => [...prev, record.id])
            api.get(`/admin/voice-acting/word/${record.id}`)
                .then(response => {
                    response.data.map(x => x.key = x.id)
                    setExpandDataSource(prev => ({...prev, [record.id]: response.data}))
                    setLoadingKeys(prev => prev.filter(key => key !== record.id))
                })
        }
    }

    const playAudio = audioFileName => {
        const url = `${window.location.origin}/audio/${audioFileName}`
        const audio = new Audio(url)
        audio.play()
    }

    const showExpandedData = record => {
        const items = expandDataSource[record.id]
        return (
            <Row>
                {
                    items.map(item => (
                        <Col key={item.id}>
                            <Card
                                actions={[
                                    item.speaker.opened
                                        ?
                                        (
                                            <Tooltip title="Открытая озвучка">
                                                <Button
                                                    shape="circle"
                                                >
                                                    <EyeOutlined />
                                                </Button>
                                            </Tooltip>
                                        )
                                        :
                                        (
                                            <Tooltip title="Закрытая озвучка">
                                                <Button
                                                    shape="circle"
                                                >
                                                    <EyeInvisibleOutlined />
                                                </Button>
                                            </Tooltip>
                                        ),
                                    <Tooltip title="Воспроизвести">
                                        <Button
                                            icon={<PlayCircleFilled/>}
                                            shape="circle"
                                            onClick={() => playAudio(item.audioFileName)}
                                        />
                                    </Tooltip>
                                ]}
                            >
                                {item.speaker.ru}
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        )
    }

    return (
        <>
            <Container style={{ marginBottom: 10 }}>
                <QazSearch
                    value={query}
                    onChange={onSearch}
                    placeholder='Поиск по словарю'
                />
            </Container>
            <CustomTable
                columns={columns}
                dataSource={words}
                pagination={pagination}
                onChange={onTableChange}
                expandable={{
                    onExpand: onRowExpand,
                    expandedRowRender: record =>
                        loadingKeys.includes(record.id) ? (
                            <div align="center">
                                <Spin />
                            </div>
                        ) : showExpandedData(record)
                }}
            />
            {recordNumberFragment}
        </>
    )

}