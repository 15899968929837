
export const QuestionAnswerPair = ({question, answer}) => {

    let questionFragment = null

    if(question) {
        questionFragment = (
            <div>
                - {question}
            </div>
        )
    }

    return (
        <>
            {questionFragment}
            <div>
                - {answer}
            </div>
        </>
    )

}