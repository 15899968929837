import { Button, Space } from 'antd'
import { useContext } from 'react'
import { CustomTable } from '../../component/CustomTable'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'
import DictionaryService from '../../service/DictionaryService'
import ExerciseService from '../../service/ExerciseService'
import { EditOutlined } from '@ant-design/icons'

export const AdminExerciseListTab = () => {
    const {lessonExercises,
        setModalOpen,
        setExercise
    } = useContext(AdminExerciseContext)

    const columns = [{
        key: 'key',
        title: '#',
        dataIndex: 'key'
    }, {
        key: 'exerciseType',
        title: 'Вид упражнения',
        dataIndex: 'exerciseType',
        render: exerciseType => (
            <span>
                {DictionaryService.getExerciseTypeValue(exerciseType)}
            </span>
        )
    }, {
        key: 'taskType',
        title: 'Вид тестового задания',
        dataIndex: '',
        render: row => (
            <span>
                {DictionaryService.getTestTaskTypeValue(row?.testTask?.taskType)}
            </span>
        )
    }, {
        key: 'description',
        title: 'Описание',
        dataIndex: '',
        render: row => (
            <span>
                {ExerciseService.getDescription(row)}
            </span>
        )
    }, {
        key: 'x',
        title: 'Действия',
        dataIndex: '',
        render: row => (
            <Space>
                <Button onClick={() => editExerciseButtonClick(row)}>
                    <EditOutlined />
                </Button>
            </Space>
        )
    }]

    const editExerciseButtonClick = exercise => {
        setExercise(exercise)
        setModalOpen(true)
    }

    return (
        <CustomTable
            columns={columns}
            dataSource={lessonExercises}
        />
    )
}