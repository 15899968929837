import { Table } from 'antd'

export const CustomTable = props => {
    return (
        <Table
            locale={{ emptyText: 'Список пуст' }}
            pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '100']
            }}
            {...props}
        />
    )
}