import api from '../../context/auth/AuthState'
import showErrorNotification from '../../helper/showErrorNotification'
import { Breadcrumb, notification, Space, Tabs, Tag, theme } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { BulbOutlined, ScheduleOutlined } from '@ant-design/icons'
import { Loading } from '../../component/Loading'
import { TryAgain } from '../../component/TryAgain'
import { Container } from '../../component/Container'
import { AdminExerciseLessonTab } from './AdminExerciseLessonTab'
import { AdminExerciseModal } from './AdminExerciseModal'
import { AdminExerciseListTab } from './AdminExerciseListTab'
import { AdminExerciseDrowdown } from './AdminExerciseDropdown'
import { AdminExerciseContext } from '../../context/adminExercise/adminExerciseContext'

export const AdminExercise = () => {

    const id = +(useParams().id)
    const {lesson, setLesson, setLessonExercises} = useContext(AdminExerciseContext)
    const [loading, setLoading] = useState(true)
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const {token} = theme.useToken()
    const [notificationApi, notificationContextHolder] = notification.useNotification()

    let activeKey = searchParams.get('tab')
    if(!activeKey) {
        activeKey = 'exercises'
    }

    useEffect(() => {
        if(id) {
            api.get(`/admin/lesson/${id}`)
                .then(response => {
                    const data = response.data
                    setLesson(data)
                    setBreadcrumbs([{
                        title: <Link to="/admin?tab=lesson">Уроки</Link>
                    }, {
                        title: <Link to={`/admin?tab=lesson&lessonGroupId=${data.lessonGroup.id}`}>{data.lessonGroup.ru}</Link>
                    },{
                        title: <span>{data.nameRu} ({data.nameKk})</span>
                    }])
                    setLoading(false)
                })
                .catch(e => {
                    showErrorNotification(notificationApi, e)
                    setLoading(false)
                })
            updateLessonExercises(id)
        } else {
            setLesson(null)
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateLessonExercises = id => {
        let rowNumber = 0
        api.get(`/admin/lesson/${id}/exercise`)
            .then(response => {
                response.data.forEach(row => {
                    row.key = ++rowNumber
                })
                setLessonExercises(response.data)
            })
            .catch(e => {
                showErrorNotification(notificationApi, e)
            })
    }

    if(loading) {
        return (
            <Loading />
        )
    }

    if(!lesson) {
        return (
            <TryAgain />
        )
    }

    const tabItems = [
        {
            label:
                <Space>
                    <BulbOutlined />
                    Урок
                </Space>,
            key: 'lesson',
            children: <AdminExerciseLessonTab lesson={lesson} />
        }, {
            label:
                <Space>
                    <ScheduleOutlined />
                    Упражнения
                </Space>,
            key: 'exercises',
            children: <AdminExerciseListTab lesson={lesson} />
        }
    ]

    const leftTabBar = (
        <Container>
            <Tag color={token.Editor.tagColor}>Проверочные упражнения</Tag>
        </Container>
    )

    const rightTabBar = (
        <AdminExerciseDrowdown />
    )

    const onTabChange = activeKey => {
        setSearchParams({tab: activeKey})
    }

    return (
        <Container>
            {notificationContextHolder}
            <Breadcrumb items={breadcrumbs} />
            <Tabs
                tabBarExtraContent={{left: leftTabBar, right: rightTabBar}}
                items={tabItems}
                activeKey={activeKey}
                onChange={onTabChange}
            />
            <AdminExerciseModal updateLessonExercises={updateLessonExercises} />
        </Container>  
    )
}