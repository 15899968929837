import axios from 'axios'
import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { CustomTable } from '../component/CustomTable'
import { QuestionAnswerPair } from '../component/QuestionAnswerPair'

export const Dialog = () => {

    const [dialogs, setDialogs] = useState([])

    const getDialogs = () => {
        let rowNumber = 0
        axios.get('/services/open-api/dialog')
            .then(response => {
                response.data.map(x => x.key = ++rowNumber)
                setDialogs(response.data)
            })
    }

    const columns = [{
        key: 'key',
        title: '#',
        dataIndex: 'key'
    }, {
        key: 'kk',
        title: 'На казахском',
        render: (_, dialog) => (
            <QuestionAnswerPair
                question={dialog.questionKk}
                answer={dialog.answerKk}
            />
        )
   }, {
        key: 'ru',
        title: 'На русском',
        render: (_, dialog) => (
            <QuestionAnswerPair
                question={dialog.questionRu}
                answer={dialog.answerRu}
            />
        )
   }]

    useEffect(() => {
        getDialogs()
    })

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={18} offset={3}>
                <CustomTable
                    columns={columns}
                    dataSource={dialogs}
                />
            </Col>
        </Row>
    )
}