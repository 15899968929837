import { Button, Input, Space } from 'antd'
import { useRef } from 'react'

export const QazSearch = ({value, onChange, placeholder}) => {

    const SPECIAL_CHARS = ['ә', 'і', 'ң', 'ғ', 'ү', 'ұ', 'қ', 'ө', 'һ']
    const inputRef = useRef(null)

    const addCharAtCursor = char => {
        const input = inputRef.current.input
        const start = input.selectionStart
        const end = input.selectionEnd
        const newValue = value
            ? value.substring(0, start) + char + value.substring(end)
            : char
        onChange(newValue)
        setTimeout(() => {
            input.selectionStart = input.selectionEnd = start + 1
            input.focus()
        }, 0)
    }

    return (
        <>
            <Input.Search
                ref={inputRef}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder || 'Введите запрос'}
                allowClear
            />
            <div style={{textAlign: 'center', marginTop: 5}}>
                <Space>
                    {
                        SPECIAL_CHARS.map(char => (
                            <Button key={char} onClick={() => addCharAtCursor(char)}>
                                {char}
                            </Button>
                        ))
                    }
                </Space>
            </div>
        </>
    )
}