import { Tabs } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { Container } from '../../component/Container'
import { AdminExerciseDescriptionList } from './AdminExerciseDescriptionList'
import { AdminLessonGroupList } from './AdminLessonGroupList'
import { AdminLessonList } from './AdminLessonList'
import { AdminWordList } from './AdminWordList'

export const Admin = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const activeKey = searchParams.get('tab')

    const items = [{
        key: 'lessonGroup',
        label: 'Группы уроков',
        children: <AdminLessonGroupList />
    }, {
        key: 'lesson',
        label: 'Уроки',
        children: <AdminLessonList />
    }, {
        key: 'word',
        label: 'Словарь',
        children: <AdminWordList />
    }, {
        key: 'exerciseDescription',
        label: 'Описания',
        children: <AdminExerciseDescriptionList />
    }]

    const onTabChange = activeKey => {
        setSearchParams({tab: activeKey})
    }

    return (
        <Container>
            <Tabs
                tabPosition='left'
                items={items}
                activeKey={activeKey}
                onChange={onTabChange}
            />
        </Container>
    )
}